import * as React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

import { HeroFullWidth } from "components/hero-images/hero-full-width/HeroFullWidth";
import { Intro } from "components/intro/Intro";
import { FullWidthBackgroundImage as Heritage3500BackgroundImage } from "components/content/full-width-background-image/FullWidthBackgroundImage";
import { NaturalMaterials } from "components/natural-materials/NaturalMaterials";
import { FullWidthVideo } from "components/content/full-width-video/FullWidthVideo";
import { TimelessHeritage } from "components/timeless-heritage/TimelessHeritage";
import { FullWidthBackgroundImage as Heritage2000BackgroundImage } from "components/content/full-width-background-image/FullWidthBackgroundImage";
import { SignatureCollection } from "components/heritage-collections/signature-collection/SignatureCollection";
import { NaturalCollection } from "components/heritage-collections/signature-collection/NaturalCollection";
import { FullWidthBackgroundImage as Heritage9000BackgroundImage } from "components/content/full-width-background-image/FullWidthBackgroundImage";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import VideoMP4 from "assets/videos/sewing.mp4";
import VideoWEBM from "assets/videos/sewing.webm";

export default () => {
  const data = useStaticQuery(graphql`
    fragment indexImages on File {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      imageHero: file(relativePath: { eq: "hero.jpg" }) {
        ...indexImages
      }
      imageAboutLeft: file(
        relativePath: {
          eq: "natural-collection/3500/3500-cover-and-border-detail.jpg"
        }
      ) {
        ...indexImages
      }
      imageAboutRight: file(
        relativePath: { eq: "natural-collection/1500/1500-cover.jpg" }
      ) {
        ...indexImages
      }
      image3500FrontView: file(
        relativePath: { eq: "natural-collection/3500/3500-front-view.jpg" }
      ) {
        ...indexImages
      }
      image2000QuarterView: file(
        relativePath: { eq: "natural-collection/2000/2000-quarter-view.jpg" }
      ) {
        ...indexImages
      }
      imageMasterCraftsmenship: file(
        relativePath: { eq: "master-craftsmenship.jpg" }
      ) {
        ...indexImages
      }
      imageSignatureCollectionLeft: file(
        relativePath: {
          eq: "signature-collection/9000/9000-heritage-beds-cover.jpg"
        }
      ) {
        ...indexImages
      }
      imageSignatureCollectionRight: file(
        relativePath: {
          eq: "signature-collection/9000/9000-heritage-beds-cover-close-up.jpg"
        }
      ) {
        ...indexImages
      }
      imageNatrualCollectionLeft: file(
        relativePath: { eq: "natural-collection/1500/1500-3-quarter-close.jpg" }
      ) {
        ...indexImages
      }
      imageNatrualCollectionRight: file(
        relativePath: { eq: "natural-collection/4500/4500-edging-detail.jpg" }
      ) {
        ...indexImages
      }
      image9000FrontViewBackground: file(
        relativePath: { eq: "signature-collection/9000/9000-front-view.jpg" }
      ) {
        ...indexImages
      }
    }
  `);
  return (
    <>
      <Helmet title="Luxury Hand Crafted Mattresses &amp; Beds" />

      <HeroFullWidth
        heroimage={
          <>
            <BackgroundImage
              Tag="section"
              fluid={data.imageHero.childImageSharp.fluid}
              backgroundColor={`#353a3c`}
            >
              <div>
                <Container>
                  <div>
                    <Row>
                      <div>
                        <h1>Luxury hand crafted beds</h1>
                      </div>
                    </Row>
                  </div>
                </Container>
              </div>
            </BackgroundImage>
          </>
        }
      />

      <div id="about-heritage">
        <Intro
          heading="About Heritage"
          description={
            <>
              <p>
                The Heritage Bed Company is an exclusive maker of exquisitely
                handcrafted mattresses. We use only the finest natural fillings
                and traditional manufacturing techniques, handed down by
                generations of bed makers to produce a range of products that
                are amongst the best in the world.
              </p>

              <p>
                We use natural materials that are responsibly sourced,
                indulgently luxurious, yet inordinately practical and durable.
                Fine cashmere, silk, cotton and wool are combined in our
                mattresses to create beds that are naturally hypo-allergenic and
                supremely comfortable. All our mattresses are made in the UK by
                craftsmen, to exacting standards, with finely finished detailing
                and our own unique blend of contemporary and classic design.
              </p>
            </>
          }
          leftimage={
            <>
              <BackgroundImage
                Tag="section"
                fluid={data.imageAboutLeft.childImageSharp.fluid}
                backgroundColor={`#353a3c`}
              />
            </>
          }
          rightimage={
            <>
              <BackgroundImage
                Tag="section"
                fluid={data.imageAboutRight.childImageSharp.fluid}
                backgroundColor={`#353a3c`}
              />
            </>
          }
        />
      </div>

      <Heritage3500BackgroundImage
        image={
          <>
            <BackgroundImage
              Tag="section"
              fluid={data.image3500FrontView.childImageSharp.fluid}
              backgroundColor={`#353a3c`}
            />
          </>
        }
      />

      <div id="natural-materials">
        <NaturalMaterials />

        <FullWidthVideo
          video={
            <>
              <video loop muted autoPlay playsInline>
                <source src={VideoMP4} type="video/mp4" />
                <source src={VideoWEBM} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </>
          }
        />

        <TimelessHeritage />

        <Heritage2000BackgroundImage
          image={
            <>
              <BackgroundImage
                Tag="section"
                fluid={data.image2000QuarterView.childImageSharp.fluid}
                backgroundColor={`#353a3c`}
              />
            </>
          }
        />
      </div>

      <div id="signature-collection">
        <SignatureCollection
          leftimage={
            <>
              <BackgroundImage
                Tag="section"
                fluid={data.imageSignatureCollectionLeft.childImageSharp.fluid}
                backgroundColor={`#353a3c`}
              />
            </>
          }
          rightimage={
            <>
              <BackgroundImage
                Tag="section"
                fluid={data.imageSignatureCollectionRight.childImageSharp.fluid}
                backgroundColor={`#353a3c`}
              />
            </>
          }
        />
      </div>

      <div
        id="natural-collection"
        style={{
          background:
            "linear - gradient(180deg, rgba(250, 250, 250, 1) 0 %, rgba(250, 250, 250, 0) 100 %)"
        }}
      >
        <NaturalCollection
          leftimage={
            <>
              <BackgroundImage
                Tag="section"
                fluid={data.imageNatrualCollectionLeft.childImageSharp.fluid}
                backgroundColor={`#353a3c`}
              />
            </>
          }
          rightimage={
            <>
              <BackgroundImage
                Tag="section"
                fluid={data.imageNatrualCollectionRight.childImageSharp.fluid}
                backgroundColor={`#353a3c`}
              />
            </>
          }
        />
      </div>

      <Heritage9000BackgroundImage
        image={
          <>
            <BackgroundImage
              Tag="section"
              fluid={data.image9000FrontViewBackground.childImageSharp.fluid}
              backgroundColor={`#353a3c`}
            />
          </>
        }
      />
    </>
  );
};
