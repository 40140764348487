import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Fade from "react-reveal/Fade";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import s from "./TimelessHeritage.scss";

export const TimelessHeritage = () => {
  const data = useStaticQuery(graphql`
    fragment timelessheritageImages on File {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      imageTimelessHeritage: file(
        relativePath: {
          eq: "signature-collection/9000/signature-9000-heritage-beds-crop.jpg"
        }
      ) {
        ...timelessheritageImages
      }
      imageCraftsmanshipAndFinish: file(
        relativePath: { eq: "craftsmanship-and-finish.jpg" }
      ) {
        ...timelessheritageImages
      }
    }
  `);
  return (
    <div className={s.timelessheritage}>
      <Container>
        <div className={s.timelessheritage__block}>
          <Row>
            <div className={s.timelessheritage__left}>
              <div className={s.timelessheritage__col}>
                <Fade left delay={200}>
                  <BackgroundImage
                    Tag="section"
                    fluid={data.imageTimelessHeritage.childImageSharp.fluid}
                    backgroundColor={`#353a3c`}
                  />
                </Fade>
              </div>
              <div className={s.timelessheritage__col}>
                <div>
                  <Fade delay={400}>
                    <h2 className={s.timelessheritage__heading}>
                      Timeless Heritage
                    </h2>
                  </Fade>
                  <Fade delay={600}>
                    <div className={s.timelessheritage__description}>
                      <p>
                        Each Heritage bed is made by master craftsmen using
                        traditional processes that have been handed down through
                        generations of bed making and each is hand crafted to an
                        exquisite quality.
                      </p>

                      <p>
                        A Heritage bed is the ultimate luxury item and takes
                        many hours to create, combining upholstering, cutting,
                        seaming and joinery techniques, all finished by hand.
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className={s.timelessheritage__right}>
              <div className={s.timelessheritage__col}>
                <div>
                  <Fade delay={400}>
                    <h2 className={s.timelessheritage__heading}>
                      Craftsmanship &amp; Finish
                    </h2>
                  </Fade>
                  <Fade delay={600}>
                    <div className={s.timelessheritage__description}>
                      <p>
                        Every mattress is filled with extra deep, high turn
                        pocket springs, and deep layers of natural carded wool
                        and natural cotton. Select our 3500, 9000 or 11500
                        mattress and you will find additional layers of lavish
                        Mongolian cashmere and silk.
                      </p>

                      <p>
                        Each bed and mattress undergoes numerous inspections
                        throughout the making process to ensure that every
                        Heritage bed is made to the same exacting standards of
                        craftsmanship and detailing. Each mattress is finished
                        with hand stitched borders, hand tufting and a
                        beautifully elegant stretch cotton damask cover.
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className={s.timelessheritage__col}>
                <Fade right delay={200}>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageCraftsmanshipAndFinish.childImageSharp.fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                </Fade>
              </div>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
};
