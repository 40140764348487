import * as React from "react";

import Swiper from "react-id-swiper";
import Fade from "react-reveal/Fade";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import ChevronLeftRegular from "assets/svg/chevron-left-regular.svg";
import ChevronRightRegular from "assets/svg/chevron-right-regular.svg";

import SlideOne from "assets/images/sliders/natural-collection/3500-front-view.jpg";
import SlideTwo from "assets/images/sliders/natural-collection/1000-drawer.jpg";
import SlideThree from "assets/images/sliders/natural-collection/1000-master-three-quarter.jpg";
import SlideFour from "assets/images/sliders/natural-collection/3500-quarter-view.jpg";
import SlideFive from "assets/images/sliders/natural-collection/1000-front-on.jpg";
import SlideSix from "assets/images/sliders/natural-collection/4500-3-quarter-close.jpg";

import s from "./Collections.scss";

interface INaturalCollectionProps {
  leftimage: React.ReactNode;
  rightimage: React.ReactNode;
}

export const NaturalCollection = ({
  leftimage,
  rightimage
}: INaturalCollectionProps) => {
  const params = {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true
    },
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom"
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev-custom">
        <ChevronLeftRegular />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next-custom">
        <ChevronRightRegular />
      </button>
    ),
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    }
  };
  return (
    <div className={s.signaturecollection}>
      <Container>
        <div className={s.signaturecollection__block}>
          <Row>
            <div className={s.signaturecollection__main}>
              <div>
                <div>
                  <Fade delay={200}>
                    <h2 className={s.signaturecollection__heading}>
                      Natural Collection
                    </h2>
                  </Fade>
                  <Fade delay={400}>
                    <h3 className={s.signaturecollection__subheading}>
                      Premium Luxury
                    </h3>
                  </Fade>
                  <Fade delay={800}>
                    <p className={s.signaturecollection__description}>
                      The Natural Collection offers an even greater choice
                      across its range with a wide variety of spring counts.
                      Each mattress is hand crafted and made with natural wool
                      and cotton, whilst the 3500 edition also benefits from
                      additional layers of cashmere and silk. Each mattress is
                      finished with flag stitched handles, hand stitched side
                      borders and a premium damask fabric cover.
                    </p>
                  </Fade>
                </div>
                <div className={s.signaturecollection__swiper}>
                  <Swiper {...params}>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideOne})` }}
                      ></div>
                    </div>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideTwo})` }}
                      ></div>
                    </div>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideThree})` }}
                      ></div>
                    </div>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideFour})` }}
                      ></div>
                    </div>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideFive})` }}
                      ></div>
                    </div>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideSix})` }}
                      ></div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className={s.signaturecollection__images}>
              <div className={s.signaturecollection__col}>{leftimage}</div>
              <div className={s.signaturecollection__col}>{rightimage}</div>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
};
