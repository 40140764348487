import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Fade from "react-reveal/Fade";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import s from "./NaturalMaterials.scss";

export const NaturalMaterials = () => {
  const data = useStaticQuery(graphql`
    fragment naturalmaterialsImages on File {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      imageNaturalLuxury: file(relativePath: { eq: "natural-luxury.jpg" }) {
        ...naturalmaterialsImages
      }
      imageWoolAndCottonSheep: file(
        relativePath: { eq: "wool-and-cotton-sheep.jpg" }
      ) {
        ...naturalmaterialsImages
      }
      imageSilkAndCashmere: file(
        relativePath: { eq: "silk-and-cashmere.jpg" }
      ) {
        ...naturalmaterialsImages
      }
    }
  `);
  return (
    <div className={s.naturalmaterials}>
      <Container>
        <div className={s.naturalmaterials__block}>
          <Row>
            <div className={s.naturalmaterials__left}>
              <div className={s.naturalmaterials__col}>
                <Fade left delay={200}>
                  <BackgroundImage
                    Tag="section"
                    fluid={data.imageNaturalLuxury.childImageSharp.fluid}
                    backgroundColor={`#353a3c`}
                  />
                </Fade>
              </div>
              <div className={s.naturalmaterials__col}>
                <div>
                  <Fade delay={400}>
                    <h2 className={s.naturalmaterials__heading}>
                      Natural Luxury
                    </h2>
                  </Fade>
                  <Fade delay={600}>
                    <div className={s.naturalmaterials__description}>
                      <p>
                        There is simply no substitute for natural materials that
                        offer not only a sumptuously comfortable sleeping
                        surface, but also regulate body temperature and are
                        naturally hypo-allergenic.
                      </p>

                      <p>
                        At the Heritage Bed Company we only use natural products
                        in our mattresses. Layers of fine wool, cotton and silk
                        are combined with deep pocket springs to create beds
                        that are the finest money can buy.
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className={s.naturalmaterials__right}>
              <div className={s.naturalmaterials__col}>
                <div>
                  <Fade delay={400}>
                    <h2 className={s.naturalmaterials__heading}>
                      Wool &amp; Cotton
                    </h2>
                  </Fade>
                  <Fade delay={600}>
                    <div className={s.naturalmaterials__description}>
                      <p>
                        Natural wool is a breathable filling that keeps you cool
                        during the summer and warm during cold winter nights and
                        provides a plush and comfortable feel. As a natural
                        fabric, it also offers natural fire retardant
                        properties, reducing the use and smell of chemicals in
                        your mattress.
                      </p>

                      <p>
                        We also use natural cotton to offer comfort and support
                        while you sleep. Soft and cool, cotton is highly
                        breathable and wicks away moisture for a fresh and dry
                        sleeping surface and is layered to provide a cushion of
                        comfort above our pocket springs.
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className={s.naturalmaterials__col}>
                <Fade right delay={200}>
                  <BackgroundImage
                    Tag="section"
                    fluid={data.imageWoolAndCottonSheep.childImageSharp.fluid}
                    backgroundColor={`#353a3c`}
                  />
                </Fade>
              </div>
            </div>
          </Row>
          <Row>
            <div className={s.naturalmaterials__left}>
              <div className={s.naturalmaterials__col}>
                <Fade left delay={200}>
                  <BackgroundImage
                    Tag="section"
                    fluid={data.imageSilkAndCashmere.childImageSharp.fluid}
                    backgroundColor={`#353a3c`}
                  />
                </Fade>
              </div>
              <div className={s.naturalmaterials__col}>
                <div>
                  <Fade delay={400}>
                    <h2 className={s.naturalmaterials__heading}>
                      Silk &amp; Cashmere
                    </h2>
                  </Fade>
                  <Fade delay={600}>
                    <div className={s.naturalmaterials__description}>
                      <p>
                        You will only find silk in the most luxurious and
                        high-end mattresses. We use it because it is a natural
                        fibre and offers enhanced comfort and moisture control,
                        helping to create a cooler, fresher sleeping
                        environment.
                      </p>
                      <p>
                        Cashmere is often defined as wool, however, cashmere
                        actually originates from rare cashmere goats. Softer and
                        finer than everyday lambswool, cashmere offers an even
                        more luxurious feel. It is not only beautifully soft,
                        but keeps you warm and helps absorb moisture to keep you
                        cool and dry.
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
};
