import * as React from "react";

import Swiper from "react-id-swiper";
import Fade from "react-reveal/Fade";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import ChevronLeftRegular from "assets/svg/chevron-left-regular.svg";
import ChevronRightRegular from "assets/svg/chevron-right-regular.svg";

import SlideOne from "assets/images/sliders/signature-collection/signature-9000.jpg";
import SlideTwo from "assets/images/sliders/signature-collection/9000-border.jpg";
import SlideThree from "assets/images/sliders/signature-collection/9000-label-and-breather-vents.jpg";
import SlideFour from "assets/images/sliders/signature-collection/11500-with-drawer.jpg";
import SlideFive from "assets/images/sliders/signature-collection/11500-top-image-no-figure.jpg";
import SlideSix from "assets/images/sliders/signature-collection/11500-border.jpg";

import s from "./Collections.scss";

interface ISignatureCollectionProps {
  leftimage: React.ReactNode;
  rightimage: React.ReactNode;
}

export const SignatureCollection = ({
  leftimage,
  rightimage
}: ISignatureCollectionProps) => {
  const params = {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true
    },
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom"
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev-custom">
        <ChevronLeftRegular />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next-custom">
        <ChevronRightRegular />
      </button>
    ),
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    }
  };
  return (
    <div className={s.signaturecollection}>
      <Container>
        <div className={s.signaturecollection__block}>
          <Row>
            <div className={s.signaturecollection__main}>
              <div>
                <div>
                  <Fade delay={200}>
                    <h2 className={s.signaturecollection__heading}>
                      Signature Collection
                    </h2>
                  </Fade>
                  <Fade delay={400}>
                    <h3 className={s.signaturecollection__subheading}>
                      Sheer Indulgence
                    </h3>
                  </Fade>
                  <Fade delay={800}>
                    <p className={s.signaturecollection__description}>
                      The Signature range represents the ultimate in absolute
                      luxury. With a choice of mattresses in generous spring
                      counts, each mattress is handcrafted and made with natural
                      wool, cashmere, silk and cotton and finished with flag
                      stitched handles, hand stitched borders and a premium
                      damask fabric cover.
                    </p>
                  </Fade>
                </div>
                <div className={s.signaturecollection__swiper}>
                  <Swiper {...params}>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideOne})` }}
                      ></div>
                    </div>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideTwo})` }}
                      ></div>
                    </div>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideThree})` }}
                      ></div>
                    </div>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideFour})` }}
                      ></div>
                    </div>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideFive})` }}
                      ></div>
                    </div>
                    <div>
                      <div
                        style={{ backgroundImage: `url(${SlideSix})` }}
                      ></div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className={s.signaturecollection__images}>
              <div className={s.signaturecollection__col}>{leftimage}</div>
              <div className={s.signaturecollection__col}>{rightimage}</div>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
};
