import * as React from "react";
import Fade from "react-reveal/Fade";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import CircleText from "assets/images/heritage-beds-circle-text.jpg";

import s from "./Intro.scss";

interface IIntroProps {
  heading: string;
  description: React.ReactNode;
  leftimage: React.ReactNode;
  rightimage: React.ReactNode;
}

export const Intro = ({
  heading,
  description,
  leftimage,
  rightimage
}: IIntroProps) => (
  <div className={s.intro}>
    <Container>
      <div className={s.intro__block}>
        <Row>
          <div className={s.intro__text}>
            <div>
              <Fade delay={200}>
                <h2 className={s.intro__heading}>{heading}</h2>
              </Fade>
              <Fade delay={600}>
                <div className={s.intro__description}>{description}</div>
              </Fade>
            </div>
            <div className={s.intro__badge}>
              <img src={CircleText} alt="Heritage Bed Co badge" />
            </div>
          </div>
        </Row>
        <Row>
          <div className={s.intro__images}>
            <div className={s.intro__col}>{leftimage}</div>
            <div className={s.intro__col}>{rightimage}</div>
          </div>
        </Row>
      </div>
    </Container>
  </div>
);
