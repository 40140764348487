import * as React from "react";

import s from "./FullWidthVideo.scss";

interface IFullWidthVideoProps {
  video: React.ReactNode;
}

export const FullWidthVideo = ({ video }: IFullWidthVideoProps) => (
  <div className={s.fullwidthvideo}>{video}</div>
);
